import React from "react"
import styles from "./article-item.module.scss"
import { get, map, has } from "lodash"
import { Image } from "react-bootstrap"
import { Link } from "gatsby"
import { parseThumbnail } from '../../../utils/frontmatter-parse'

export default function ArticleItem({ data }) {
  return (
    <article className={`${styles.blogEntry}`}>
      <div className={`${styles.inner}`}>
        <div className={`${styles.thumbnail}`}>
          {
            has(data, 'frontmatter.thumbnail') && get(data, 'frontmatter.thumbnail') ? (
            <Link
              to={get(data, "frontmatter.permalink")}
              className={`${styles.thumbnailLink}`}
            >
              <Image src={parseThumbnail(get(data, "frontmatter.thumbnail"))}/>
            </Link>) : null
          }
        </div>
        <header className={`${styles.blogEntryHeader}`}>
          <h2 className={`${styles.blogEntryTitle}`}>
            <Link
              to={get(data, "frontmatter.permalink")}
              title={get(data, "frontmatter.title")}
              rel={"bookmark"}
            >
              {get(data, "frontmatter.title")}
            </Link>
          </h2>
        </header>
        <ul className={`${styles.meta}`}>
          <li className={`${styles.metaAuthor}`}>
            <i className="bx bx-user"></i>
            {map(get(data, "frontmatter.author", []), person => (
              <Link to={`/author/${person}`}>{person}</Link>
            ))}
          </li>
          <li className={`${styles.metaDate}`}>
            <i className={`bx bx-time`}></i>
            {get(data, "frontmatter.date")}
          </li>
          <li className={`${styles.metaCat}`}>
            <i className={`bx bx-folder`}></i>
            {map(get(data, "frontmatter.category", []), (cat, i) => {
              const divider =
                i !== 0 ? <span className={`${styles.ownSep}`}>/</span> : null
              return (
                <>
                  {divider}
                  <Link to={`/category/${cat}`} rel={`category tag`}>
                    {cat}
                  </Link>
                </>
              )
            })}
          </li>
        </ul>
        <div className={`${styles.blogEntrySummary}`}>
          <p>{get(data, "excerpt")}</p>
        </div>
        <div className={`${styles.blogEntryReadmore}`}>
          <Link to={get(data, "frontmatter.permalink")}>
            Continue Reading
            <i className={`bx bx-right-arrow-alt`} />
          </Link>
        </div>
      </div>
    </article>
  )
}
